<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="dialogForm"
      :before-close="handleClose">
    <div style="font-size: 12px">支持上传图片格式：jpg、png、jpeg，单个文件不超过100M，最多上传{{ imgFileNumr }}张</div>
    <el-form size="small" :label-position="'top'" ref="inputForm" :model="inputForm" :rules="rules" label-width="120px">
      <el-form-item :label="inputLabelName + '前图片'" prop="beforeProcessingList">
        <div class="flex_l_t" style="flex-wrap: wrap">
          <div
              v-for="(item,index) in inputForm.beforeProcessingList" :key="item.id"
              style="position: relative;padding-bottom: 10px;width: 105px;margin-right: 15px"
          >
            <div class="el-icon-error" @click="deleteFile(item,index,'beforeProcessingList')" v-if="!inputType"
                 style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
            <el-image
                style="width: 100%; height: 110px;"
                :src="item.netUrl"
                :fit="'cover'"
                :preview-src-list="[item.netUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 100%; height: 110px;"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
              <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{ item.fileName }}</div>
            </el-tooltip>
          </div>
          <resume-upload :upDateType="'5645447'" class="imgUpData" :frameUpDataHeight="110" ref="resume-upload1"
                         :disabledBut="inputType"
                         :uploadNum="imgFileNumr" :uploadProgressShow="false"
                         :upFileSize="upFileSize" :allowFilesShow="false"
                         :allowFiles="imgPattern"
                         @upFileDate="(data)=>getUpData(data,'beforeProcessingList')"></resume-upload>
        </div>
      </el-form-item>
      <el-form-item :label="inputLabelName + '中图片'" prop="isProcessingList">
        <div class="flex_l_t" style="flex-wrap: wrap">
          <div
              v-for="(item,index) in inputForm.isProcessingList" :key="item.id"
              style="position: relative;padding-bottom: 10px;width: 105px;margin-right: 15px"
          >
            <div class="el-icon-error" @click="deleteFile(item,index,'isProcessingList')" v-if="!inputType"
                 style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
            <el-image
                style="width: 100%; height: 110px;"
                :src="item.netUrl"
                :fit="'cover'"
                :preview-src-list="[item.netUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 100%; height: 110px;"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
              <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{ item.fileName }}</div>
            </el-tooltip>
          </div>
          <resume-upload :upDateType="'56447651'" class="imgUpData" :frameUpDataHeight="110" ref="resume-upload2"
                         :disabledBut="inputType"
                         :uploadNum="imgFileNumr" :uploadProgressShow="false"
                         :upFileSize="upFileSize" :allowFilesShow="false"
                         :allowFiles="imgPattern"
                         @upFileDate="(data)=>getUpData(data,'isProcessingList')"></resume-upload>
        </div>
      </el-form-item>
      <el-form-item :label="inputLabelName + '后图片'" prop="afterProcessingList">
        <div class="flex_l_t" style="flex-wrap: wrap">
          <div
              v-for="(item,index) in inputForm.afterProcessingList" :key="item.id"
              style="position: relative;padding-bottom: 10px;width: 105px;margin-right: 15px"
          >
            <div class="el-icon-error" @click="deleteFile(item,index,'afterProcessingList')" v-if="!inputType"
                 style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
            <el-image
                style="width: 100%; height: 110px;"
                :src="item.netUrl"
                :fit="'cover'"
                :preview-src-list="[item.netUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 100%; height: 110px;"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
              <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{ item.fileName }}</div>
            </el-tooltip>
          </div>
          <resume-upload :upDateType="'54751565'" class="imgUpData" :frameUpDataHeight="110" ref="resume-upload3"
                         :disabledBut="inputType"
                         :uploadNum="imgFileNumr" :uploadProgressShow="false"
                         :upFileSize="upFileSize" :allowFilesShow="false"
                         :allowFiles="imgPattern"
                         @upFileDate="(data)=>getUpData(data,'afterProcessingList')"></resume-upload>
        </div>
      </el-form-item>
    </el-form>
    <div class="text_center" slot="footer">
      <el-button size="small" type="primary" @click="sureData()" v-no-more-click>保存</el-button>
      <el-button size="small" @click="handleClose()">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  components: {resumeUpload},
  data() {
    return {
      dialogVisible: false,
      selectData: {},
      mountId: '',
      code: 'xggl',
      searchtype: '1',
      inputForm: {
        beforeProcessingList: [],
        isProcessingList: [],
        afterProcessingList: [],
      },
      rules: {},

      inputType: false, //是否禁用上传
      imgFileNumr: 10,
      upFileSize: 100,
      imgPattern: ['JPG', 'PNG', 'JPEG'],

      title:'',
      inputLabelName:'',
    }
  },
  methods: {
    init(row, mountId, code, type) {
      this.selectData = row
      this.mountId = mountId
      this.code = code
      this.searchtype = type
      if (code == 'xggl') {
        this.title = '事故图片';
        this.inputLabelName = '事故处理';
        this.$set(this.rules,'beforeProcessingList',[
          {type: 'array', required: true, message: '请上传事故处理前图片', trigger: 'change'}
        ])
      }
      if (code == 'cpzb') {
        this.title = '装裱图片';
        this.inputLabelName = '装裱';
        this.$set(this.rules,'beforeProcessingList',[
          {type: 'array', required: true, message: '请上传装裱前图片', trigger: 'change'}
        ])
        this.$set(this.rules,'afterProcessingList',[
          {type: 'array', required: true, message: '请上传装裱后图片', trigger: 'change'}
        ])
      }
      if (code == 'bhqk') {
        this.title = '保护图片';
        this.inputLabelName = '保护';
        this.$set(this.rules,'beforeProcessingList',[
          {type: 'array', required: true, message: '请上传保护前图片', trigger: 'change'}
        ])
        this.$set(this.rules,'afterProcessingList',[
          {type: 'array', required: true, message: '请上传保护后图片', trigger: 'change'}
        ])
      }
      this.dialogVisible = true
      this.$axios(this.api.collection.getByCode, {
        businessCode: this.code,
      }, 'get').then((res) => {
        if (res.status) {
          this.imgFileNumr = res.data
        }
      })
      if (row.fileCount > 0) { //查询图片
        this.$nextTick(() => {
          this.$axios(this.api.collection.getUploadImg, {
            mountId: this.mountId,
            dataId: this.selectData.id,
            type: this.searchtype,
          }, 'get').then((res) => {
            if (res.status) {
              this.inputForm.beforeProcessingList = res.data.beforeProcessingList || []
              this.$refs['resume-upload1'].setOldFile(this.inputForm.beforeProcessingList)
              this.inputForm.isProcessingList = res.data.isProcessingList || []
              this.$refs['resume-upload2'].setOldFile(this.inputForm.isProcessingList)
              this.inputForm.afterProcessingList = res.data.afterProcessingList || []
              this.$refs['resume-upload3'].setOldFile(this.inputForm.afterProcessingList)
            }
          })
        })
      }
    },

    //获取上传文件
    getUpData(data, name) {
      this.inputForm[name] = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          if (name == 'beforeProcessingList') {
            this.$set(item.upDate, 'processingType', 0)
          }
          if (name == 'isProcessingList') {
            this.$set(item.upDate, 'processingType', 1)
          }
          if (name == 'afterProcessingList') {
            this.$set(item.upDate, 'processingType', 2)
          }
          return item.upDate
        } else {
          return item
        }
      })
    },

    deleteFile(row, index, namList) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (namList == 'beforeProcessingList') {
          this.inputForm.beforeProcessingList.splice(index, 1)
          this.$refs["resume-upload1"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (namList == 'isProcessingList') {
          this.inputForm.isProcessingList.splice(index, 1)
          this.$refs["resume-upload2"].deleteFile(row.fileID ? row.fileID : row.id)
        }
        if (namList == 'afterProcessingList') {
          this.inputForm.afterProcessingList.splice(index, 1)
          this.$refs["resume-upload3"].deleteFile(row.fileID ? row.fileID : row.id)
        }
      })
    },

    //保存
    sureData() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          this.$axios(this.api.collection.uploadImg, {
            isProcessingList: [
              ...this.inputForm.beforeProcessingList,
              ...this.inputForm.isProcessingList,
              ...this.inputForm.afterProcessingList
            ],
            type: this.searchtype,
            mountId: this.mountId,
            dataId: this.selectData.id,
          }, 'post').then((res) => {
            if (res.status) {
              this.$message.success('保存成功')
              this.$emit('refreshList')
              this.handleClose()
            } else {
              this.$message.error(res.msg);
            }
          })
        }
      });
    },

    handleClose() {
      this.inputForm.beforeProcessingList.forEach(item => {
        this.$refs["resume-upload1"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
      })
      this.inputForm.isProcessingList.forEach(item => {
        this.$refs["resume-upload2"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
      })
      this.inputForm.afterProcessingList.forEach(item => {
        this.$refs["resume-upload3"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
      })
      this.inputForm.imgList = []
      this.$refs.inputForm.resetFields()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.imgUpData {
  width: 110px;
}
</style>